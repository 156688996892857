<script>
import Layout from "@/views/layouts/main";
import { mask } from 'vue-the-mask'
import { onMounted, ref } from "vue";
import { getProfile, updateProfile } from "./api";
import MultiAlerts from "@/components/multi-alerts";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  setup() {
    const store = ref()
    const multiAlertsRef = ref(null)
    const myAccount = ref({
      name: "",
      whatsapp: "",
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
    });

    onMounted(() => {
      getMyAccount();
    });

    async function getMyAccount(){
      try {
        const response = await getProfile();
        myAccount.value = response.user;

      }catch (error) {
        Toasts('error', 'Falha ao carregar')
      }
    }

    async function submitMyAccount(){
      multiAlertsRef.value.show([])
      try {
        if (myAccount.value.password !== myAccount.value.password_confirmation){
          Toasts('warning', 'Por favor verifique sua senha!')
          return
        }else {
          await updateProfile(myAccount.value)

          Toasts('success', 'Perfil atualizado com sucesso!')
        }
      }catch (e) {
        const message = Object.values(e?.response?.data?.errors ?? {}).join('<br>')
        multiAlertsRef.value.show(message)
      }
    }

    return {
      myAccount,
      store,
      getMyAccount,
      submitMyAccount,
      multiAlertsRef
    };
  },
  components: {
    Layout,
    MultiAlerts
  },
  directives: {
    mask,
  },
};

</script>

<template>
  <Layout :pagetitle="'Minha conta'">
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Informações gerais</h4>
          </div>
          <div class="card-body">
            <form
                @submit.prevent="submitMyAccount"
                class="was-validated"
                >
              <multi-alerts ref="multiAlertsRef"/>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label"
                      >Nome completo</label
                    >
                    <input
                      v-model="myAccount.name"
                      class="form-control"
                      id="name"
                      placeholder="First name"
                      required
                    />
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="WhatsApp" class="form-label"
                      >WhatsApp</label
                    >
                    <input
                      v-model="myAccount.whatsapp"
                      class="form-control"
                      id="WhatsApp"
                      placeholder="WhatsApp"
                      required
                    />
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="userName" class="form-label"
                      >Nome de vendedor</label
                    >
                    <input
                        v-model="myAccount.username"
                        class="form-control"
                        id="userName"
                        placeholder="Nome do vendedor"
                        required
                    />
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="email" class="form-label"
                      >E-mail</label
                    >
                    <input
                      v-model="myAccount.email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="E-mail"
                      required
                    />
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="password" class="form-label"
                    >Senha</label
                    >
                    <input
                        v-model="myAccount.password"
                        type="password"
                        class="form-control"
                        id="password"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="passwordConfirm" class="form-label"
                    >Confirmar senha</label
                    >
                    <input
                        v-model="myAccount.password_confirmation"
                        type="password"
                        class="form-control"
                        id="passwordConfirm"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary" type="submit">
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>