<template>
  <div class="row">
    <div class="col">
      <div
        v-for="(message, i) in messages"
        :key="i"
        role="alert"
        class="alert alert-danger"
      >
        <span v-html="message"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const messages = ref([]);

    const show = (input) => {
      const toArray = Array.isArray(input) ? input : [input]
      messages.value = toArray
    }

    return {
      messages,
      show
    }
  },
}
</script>
